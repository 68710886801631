// eyecatcher
.eyecatcher {
	position: relative;

	&:after {
		content: '';
		background: url('/images/banner_golfjes.svg');
		width: 100%;
		position: absolute;
		height: 107px;
		left: 0;
		bottom: 0;
		background-size: cover;
		background-position-x: 67px;

		@include media-breakpoint-down (md) {
			height: 111px;
		}
	}

	.owl-btn {
		background: transparent !important;
		border: 1px solid #fff !important;
		color: #fff !important;
		min-width: 214px;
	}


	ul.vocabulary-term-list {
		display: flex;
		flex-flow: row wrap;
		min-width: 150px;
		justify-content: flex-end;
		position: absolute;
		bottom: 100px;
		right: 50px;
		@include media-breakpoint-down(md) {
			display: none !important;
		}

		li {
			width: 50px;

			&:not(:last-of-type) {
				margin-right: 15px;
			}


			.list-item-title {
				display: none;
			}
		}
	}
}

.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.owl-carousel {
	z-index: auto;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel {
	height: 85vh;
	min-height: 540px;
	max-height: 925px;
	@include media-breakpoint-down(md) {
		min-height: 60vh;
		height: 60vh;
		max-height: 60vh;
	}

	.owl-stage-outer {
		height: 100%;

		.owl-stage {
			height: 100%;
		}


	}

	.item {
		height: 100%;
		min-height: 540px;
		max-height: 925px;
		z-index: auto;
		align-items: flex-start;
		@include media-breakpoint-down(md) {
			min-height: 60vh;
			height: 60vh;
			max-height: 60vh;
		}


		.owl-caption {
			z-index: 2;
			position: relative;

			.owl-title {
				font-size: $homebanner-font-size;
				text-shadow: none;
				max-width: 727px;
				margin-left: auto;
				margin-right: auto;
				margin-top: 125px;
				@include media-breakpoint-down(md) {
					margin-top: 25px;
					font-size: $font-size-base *3;
				}
			}
		}
	}
}

.owl-carousel,
.owl-stage-outer,
.owl-stage-outer .owl-stage,
.owl-stage-outer .owl-stage .owl-item,
.owl-stage-outer .owl-stage .owl-item .item {
	height: 100%;
}

.owl-stage-outer .owl-stage .owl-item .item:after {
	content: '';
	background: $secondary;
	opacity: .11;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	pointer-events: none;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
	width: 24px;
	height: 24px;
	font-size: 16px;
	top: 50%;
	background: #fff;
	border-radius: 50px;
	box-shadow: 0px 3px 6px #00000029;
}

.owl-carousel .owl-nav button.owl-next i,
.owl-carousel .owl-nav button.owl-prev i {
	position: relative;
	top: -6px;
	left: -1px;
	color: $primary;
}

.owl-carousel .owl-nav button.owl-next i {
	left: 2px;
}

.owl-carousel .owl-nav button.owl-next {
	right: 35px;
}

.owl-carousel .owl-nav button.owl-prev {
	right: 65px;
	left: auto;
}

section {
	+ .eyecatcher {
		.owl-carousel {
			&.large .item {
				align-items: center;

				.owl-caption {
					.owl-title {
						margin-top: 0;
					}
				}
			}
		}
	}
}
