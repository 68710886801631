// btn
.btn {
	box-shadow: none;
	font-weight: 500;
	text-transform: none;
	position: relative;

	&:after {
		content: "\f054";
		font-family: $font-awesome;
		margin-left: 15px;
		position: relative;
		top: 1px;
	}

	&:hover {
		&:after {
			left: 3px;
		}
	}

	// btn-primary
	&.btn-primary {
		&:hover {
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
		}
	}

	// btn-secondary
	&.btn-secondary {
		// ???
		// &:after {
		// 	position: absolute;
		// 	right: 15px;
		// 	top: 50%;
		// 	transform: translateY(-50%);
		// }

		&:hover {
			background: transparent;
			color: $secondary!important;
		}
		color: $white!important;
		span {
			color: $white!important;
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		min-width: 230px;

		&:hover {
		}
	}

	&.btn-transparent {
		padding: 0;
	}

	&.btn-outline-white {
		color: #fff;
		border: 1px solid #fff;
	}
}

p {
	.btn {
		margin-top: 10px;
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
