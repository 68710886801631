.owl-carousel {
    &.slider {
        .owl-nav {
            .owl-prev {
                @include media-breakpoint-down(sm) {
                    left: -12px;
                }
            }
            .owl-next {
                @include media-breakpoint-down(sm) {
                    right: -12px;
                }
            }
        }
    }
}