.card {
	background: $primary;
	border: none;
	border-radius: 8px;

	.card-body {
		padding: 20px 18px 60px;
	}

	* {
		color: #000;
		transition: 0.5s;

		&.card-title {
			font-family: $font-family-primary;
			font-weight: 600;
			font-size: $h3-font-size;
			margin-bottom: 0;
		}
		&.card-subtitle {
			font-weight: 400;
			font-size: $h4-font-size;
		}
	}

	&:hover {
		* {
			color: $secondary !important;
		}
	}
}

.contentblock:not(.wide-cards) {
	.card {
		.card-title {
			position: relative;

			&:after {
				content: "\f138";
				font-family: $font-awesome;
				position: absolute;
				right: 0;
			}
		}

		&:hover {
			.card-title {
				&:after {
					right: -3px;
				}
			}
		}
	}
}

.lead-section {
	.contentblock:not(.wide-cards) {
		.card {
			.card-title {
				&:after {
					display: none;
				}
			}
		}
	}
}

.contentblock.wide-cards {
	.card {
		.card-caption {
			.card-description {
				@include media-breakpoint-down(md) {
					margin-bottom: 5px;
				}
			}
		}
	}
}
