.collection.grid.wide-cards {
	max-width: 720px;
	margin: 0 auto;

	.grid-items {
		.item {
			flex: 0 0 100%;
			max-width: 100%;

			a {
				background: var(--yellow);

				.card-image {
					display: none;
				}

				.card-body {
					flex-flow: row wrap !important;
					flex: 0 0 100%;
					max-width: 100%;
					padding: 20px 15px 18px 30px;

					.card-caption {
						flex: 0 0 65%;
						max-width: 65%;
						@include media-breakpoint-down(md) {
							flex: 0 0 100%;
							max-width: 100%;
						}

						.card-title {
							color: $secondary;
							margin-bottom: 0;
						}
					}

					.card-buttons {
						flex: 0 0 35%;
						max-width: 35%;
						margin-top: 0;
						display: flex;
						justify-content: flex-end;
						align-items: center;
						@include media-breakpoint-down(md) {
							flex: 0 0 100%;
							max-width: 100%;
							justify-content: flex-start;
						}

						.card-btn {
							@extend .btn-secondary;
							min-width: 180px;
							text-transform: none;
							text-align: left;
						}
					}
				}

				&:hover {
					.card-buttons {
						.card-btn {
							@extend :hover;

							&:after {
								left: auto;
								right: 10px !important;
							}
						}
					}
				}
			}
		}
	}
}
