// footer
.footer {
	div.floating-button {
		position: fixed;
		right: 1.5rem;
		bottom: 1.5rem;
		z-index: 1000;

		a {
			display: flex;
			flex-flow: column;
			border: 1px solid $primary;
			background: $white;
			box-shadow: 0px 0px 4px #00000029;
			text-decoration: none;
			border-radius: 4px;
			padding: 0.25rem 1rem;
			box-shadow: $shadow;

			img {
				order: 1;
				max-width: 142px;
			}

			.caption {
				.list-item-title {
					font-size: 12px;
					font-weight: 600;
					font-family: $font-family-primary;
					color: $primary;
					text-decoration: none;
					letter-spacing: 1.2px;
				}
			}

			.list-item-suffix,
			.list-item-short-description {
				display: none;
			}

			&:hover {
				border: 1px solid $secondary;
			}
		}

		@include media-breakpoint-down(md) {
			transform: scale(0.8);
			right: 0.5rem;
		}
	}
	section {
		&.footer-section {
			h5,
			p {
				color: #fff;
			}

			ul {
				list-style: none;
				padding-left: 0;
			}

			li,
			li a:not(.btn) {
				color: $primary !important;
				text-decoration: none !important;
			}

			li a:hover {
				text-decoration: underline !important;
			}
		}
	}

	h5 {
		font-family: $font-family-primary;
		font-weight: 400;
	}

	.footer-content {
		text-align: center;

		.footer-text {
			margin-bottom: 35px;

			p {
				margin: 0;
			}
		}

		ul.footer-socials {
			display: flex;
			flex-flow: row wrap;
			justify-content: center;

			> li {
				max-width: 220px;
				width: 100%;

				&:not(:last-of-type) {
					margin-right: 15px;
					@include media-breakpoint-down(md) {
						margin-right: 0;
						margin-bottom: 15px;
					}
				}

				> a {
					display: flex;
					flex-flow: row wrap;
					background: $primary;
					border-radius: 5px;
					text-decoration: none;
					position: relative;
					max-width: 220px;
					padding: 11.5px 0;
					padding-left: 40px;
					border: 1px solid $primary;
					width: 100%;

					.list-item-prefix {
						position: absolute;
						left: 12px;
						top: 50%;
						transform: translateY(-50%);
						font-size: 22px;
					}

					.list-item-title,
					.list-item-suffix {
						flex: 0 0 100%;
						max-width: 100%;
						text-align: left;
					}

					.list-item-title {
						color: #fff;
						font-weight: 500;
						line-height: 15px;
					}

					.list-item-suffix {
						font-size: $font-size-small;
					}

					&:hover {
						background: #fff;

						.list-item-title,
						.list-item-suffix {
							color: $primary;
						}
					}
				}
			}
		}
	}

	.footer-section {
		margin-top: 12vh;
		padding-bottom: 2vh;
		@include media-breakpoint-down(md) {
			margin-top: 6vh;
		}

		&:before {
			top: -25px;
			content: "";
			background: url("/images/golfjes_wit.svg");
			width: 100%;
			height: 47px;
			position: absolute;
			left: 0;
			background-size: 100% 100%;
			z-index: 1;
			transform: rotate(180deg);
			@include media-breakpoint-down(md) {
				background-size: cover;
			}
		}

		ul.vocabulary-term-list {
			li {
				margin-right: 15px;

				a {
					font-size: 32px;

					i {
						color: #fff;
					}

					.list-item-title {
						display: none;
					}

					&:hover {
						i {
							opacity: 0.8;
						}
					}
				}
			}
		}
	}

	.footer-link {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;

		ul {
			li {
				&:not(:last-of-type) {
					margin-right: 15px;
				}

				a.link {
					color: #fff !important;
				}
			}
		}
	}

	.footer-socket {
		ul {
			list-style: none;
			padding-left: 0;
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			margin: 30px 0;

			li {
				&:not(:last-of-type) {
					margin-right: 15px;
				}
			}
		}
	}
}
