// old base file

// override source styling: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
.mini-sab .tommy-zeb-wrapper {
	float: none;
	width: auto;
}

.mini-sab .tommy-zeb-wrapper * {
	font-family: $font-family-base !important;
	font-size: 12px;
	float: none !important
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left {
	// flex-wrap: wrap;
	float: none;
	background: none;
	color: $black;
	padding: 0 !important;
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left label {
	color: $white;
}


.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left {
	justify-content: center !important;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types {
	margin-right: 15px;
	padding-right: 0;
	border-right: none;
	flex: 1 0 auto;
}

.mini-sab .tommy-zeb-wrapper-mini h2.ng-binding {
	display: none;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category-label {
	display: none; 
}

/* Accommodatietype */
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types h2.ng-binding {
	display: inline-block;
	margin: 0;
	color: $white;
	font-size: 12px;
	font-weight: 400;
	line-height: 30px;
	margin-bottom: 0 !important;
	text-align: left;
	text-transform: none;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types select {
	max-width: 200px;
	min-width: 150px;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category select {
	width: 120px;
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left input {
	line-height: 20px;
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left input,
.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select {
	padding: 5px;
	margin: 0;
	width: 100%;
}

.tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date {
	flex: 0 0 280px;
    max-width: 280px;
}

.mini-sab .tommy-zeb-period {
	display: flex !important;
}

.mini-sab .tommy-zeb-period .tommy-zeb-date-begin {
	margin-right: 15px;
}

.mini-sab .tommy-zeb-period .tommy-zeb-date-begin-label,
.mini-sab .tommy-zeb-period .tommy-zeb-date-end-label {
	float: none !important;
	display: block;
}

.mini-sab .tommy-zeb-period .tommy-zeb-date-begin-label label, .mini-sab .tommy-zeb-period .tommy-zeb-date-end-label label {
	margin-bottom: 0 !important;
	line-height: 30px;
	display: block;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 1.4px;
}

.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date-begin,
.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date-end {
	padding: 0;
	float: left;
	margin-bottom: 0;
	flex: 0;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories, .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date {
	border-right: none;
	padding-right: 0;
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-period input {
	background-color:  $white;
	border: 1px solid  $white;
	border-radius: 2px;
	padding: 6px 10px;
	height: 36px;
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select {
	padding-top: 4px;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-period input {
	width: 130px;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-confirm-update {
	display: flex;
    align-content: flex-end;
    flex-wrap: wrap;
}

.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-update {
	min-width: 120px;
	height: 36px;
	margin: 0 !important;
	padding: 8px 20px;
	border-radius: 2px;
	border-width: 3px;
	border-color: var(--yellow);
	background-color: var(--yellow);
	color: $secondary;
	font-size: 14px;
	letter-spacing: 1.4px;
	font-weight: 500;
	line-height: 20px;
	text-transform: uppercase;
	box-shadow: $shadow;
}

.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-update:hover {
	border-color: $secondary;
	background-color: $secondary;
	color: $white;
}

/* collapse functionaliteit */
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories h2.ng-binding {
	display: block;
	margin: 0;
	color: $white;
	font-size: 12px;
	font-weight: 400;
	line-height: 30px;
	margin: 0 !important;
	text-align: left;
	text-transform: none;
	letter-spacing: 1.4px;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories h2.ng-binding::after {
	display: none;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories-collapse {
	display: flex;
	min-width: auto;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories-collapse .tommy-zeb-travelcompany-total {
	border: 1px solid  $white;
	border-radius: 2px;
	padding: 6px;
	// width: 100% !important;
	height: 36px;
	margin-top: 0 !important;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-category-list {
	z-index: 99;
	background-color:  $white;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
	border-radius: 2px;
	color: #000;
	width: 100%;
	max-width: 100%;
	min-width: 260px;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-category-list .ng-scope {
	padding: 0;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category {
	flex-wrap: nowrap;
	margin: 5px 0;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category .tommy-zeb-accommodation-person-category-label {
	flex: 1 1 auto;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category .tommy-zeb-accommodation-person-category-choice {
	flex: 0 0 85px;
	align-self: center;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category-choice .ng-binding {
	min-width: 20px;
	line-height: 22px;
	text-align: center;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-range {
	background-color: $primary;
	color:  $white;
	line-height: 22px;
	transition: all 0.5s ease;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-range:hover {
	background-color: $secondary;
	color:  $white;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-range span {
	line-height: 24px;
	top: 0 !important;
}

.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-change-person-categories {
	height: 36px;
	padding: 7px 14px;
	color: #000;
	font-weight: 400;
	border-radius: 3px;
	text-transform: none;
	background-color:  $white;
	border: 1px solid  $white;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-person-category-mini .tommy-zeb-person-categories-collapse button.tommy-zeb-btn {
	margin-top: 0 !important;
}

.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-change-person-categories::after {
	font-family: "Font Awesome 5 Pro";
	content: "\f107";
	margin-left: 15px;
}

.tommy-zeb-wrapper .tommy-zeb-left {
	background: $zab-background;
}

.tommy-zeb-wrapper * h2 {
	font-size: $font-size-base * 0.675;
	font-weight: 500;
	font-family: $font-family-secondary !important;
	color: $white !important;
	margin-bottom: 10px !important;
	@include media-breakpoint-down(md) {
		min-height: 26px;
	}
}

.tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-person-category-mini .tommy-zeb-person-categories-collapse button.tommy-zeb-btn {
	display: none;
}

.tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-person-category-mini .tommy-zeb-person-categories-collapse .tommy-zeb-travelcompany-total,
.tommy-zeb-wrapper .tommy-zeb-left select {
	width: 180px;
	margin-left: 0;
	text-align: left;
	height: 40px;
	padding: 0 10px;
	background:  $white;
	border-radius: 2px;
	margin-top: 0 !important;
}

.tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-period input {
	width: 130px;
}

.tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories, .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types {
	border-right: none;
	padding-right: 0;
	width: 100%;
	max-width: 180px;
	margin-right: 20px;
}

.tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-person-category-mini .tommy-zeb-person-categories-collapse {
	min-width: 100%;
}

tommy-period .tommy-zeb-period {
	display: flex !important;
	flex-flow: row wrap;
}

tommy-period .tommy-zeb-period > div {
	display: flex !important;
	flex-flow: row wrap !important;
}

&.accommodation-search-book tommy-period .tommy-zeb-period > div {
	flex-flow: row wrap !important;
}

&.accommodation-search-book .tommy-zeb-wrapper .tommy-zeb-date-begin {
	margin-bottom: 15px !important;
}

&.accommodation-search-book tommy-period .tommy-zeb-period > div {
	flex: 0 0 100%;
	max-width: 100%;
}

tommy-period .tommy-zeb-period > div > div {
	flex: 0 0 100%;
	max-width: 100%;
	text-align: left;
	// margin-right: 20px;
}

.tommy-zeb-wrapper-mini button.tommy-zeb-btn {
	margin-top: 23px !important;
	height: 40px;
	background: $yellow;
	color: $secondary;
	text-transform: uppercase;
	border-radius: 2px;
	font-weight: 600;
	text-align: left !important;
	position: relative;
	padding: 0 15px;
	min-width: 120px;

	&:hover {
		background: $secondary;
		color: $yellow;
	}
}

.tommy-zeb-wrapper-mini button.tommy-zeb-btn:after {
	content: '\f138';
	font-family: $font-awesome;
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
	font-weight: 700;
}

// &:not(.accommodation-search-book) tommy-period .tommy-zeb-period > div > div[class*='-label'] {
// 	display: none;
// }

tommy-period .tommy-zeb-period > div > div input {
	height: 40px;
	border-radius: 2px !important;
	margin: 0 !important;
}

.is-today .pika-button {
	background-color: $yellow;
	color: $secondary;
}

.pika-button {
	background-color: $yellow;
	opacity: .7;
}

.pika-button:hover {
	background-color: $secondary;
	color:  $white;
}

.tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-person-category-mini .tommy-zeb-person-category-list {
	z-index: 2;
	width: 100vw;
	max-width: 270px;
	background: $yellow;
}

.tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-person-category-mini .tommy-zeb-range {
	background: $secondary;
}

.tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-person-category-mini .tommy-zeb-accommodation-person-category-choice .tommy-zeb-min span,
.tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-person-category-mini .tommy-zeb-accommodation-person-category-choice > * > span {
	color: $yellow;
}

.tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-person-category-mini .tommy-zeb-accommodation-person-category .tommy-zeb-accommodation-person-category-choice {
	flex: 0 0 115px;
}

.mini-sab {
	@media(max-width: 1199px) {
		display: none;
	}

	tommy-search-and-book-mini {
		> div {
			display: flex !important;
		}
	}

	.tommy-zeb-left {
		background: $primary;
		justify-content: center !important;
		// margin-top: 0;
		// margin-bottom: 50px;
		// max-width: 820px;
		margin-left: auto;
		margin-right: auto;
		@media(max-width: 1199px) {
			padding: 15px !important;
			position: relative;
			z-index: 2;
		}

		tommy-confirm-update {
			button.tommy-zeb-btn {
				margin-right: 0 !important;
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 1199px) {

	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category-label,
	.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-begin-label,
	.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-end-label {
		width: auto !important;
	}

}

@media(max-width: 1199px) {
	.tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between !important;
		padding: 0;
	}
	.tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories,
	.tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types {
		flex: 0 0 calc(50% - 7.5px);
		max-width: calc(50% - 7.5px);
		margin: 0;
		margin-bottom: 15px;
		border-bottom: none;
		@include media-breakpoint-down(md) {
			padding-bottom: 0;
		}

		tommy-person-category-mini,
		tommy-accommodation-type {
			width: 100%;

			input,
			select {
				width: 100% !important;
				max-width: 100%;
			}
		}
	}
	.tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date {
		flex: 0 0 100%;
		max-width: 100%;

		tommy-period {
			width: 100%;

			> .tommy-zeb-period {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;

				> div {
					flex: 0 0 calc(50% - 7.5px);
					max-width: calc(50% - 7.5px);
					margin: 0;

					input {
						width: 100%;
					}
				}
			}
		}
	}
	.tommy-zeb-wrapper-mini tommy-confirm-update {
		width: 100%;
		max-width: 100%;

		button.tommy-zeb-btn {
			width: 100%;
			max-width: 100%;
		}
	}
}

@media (max-width: 767px) {

	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left {
		display: block;
	}
	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories,
	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types {
		border-bottom: none;
		padding: 0;
		margin: 0;
	}
	.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date,
	.mini-sab .tommy-zeb-wrapper-mini .ng-isolate-scope {
		float: none;
		clear: both;
	}
	.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn {
		margin: 0 !important;
	}
	.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-begin-label,
	.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-end-label {
		width: auto;
	}
	.mini-sab .tommy-zeb-wrapper-mini tommy-confirm-update {
		display: block;
	}

	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types select {
		max-width: none;
	}
	.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select,
	.mini-sab .tommy-zeb-wrapper .tommy-zeb-left input,
	.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn {
		display: block !important;
		width: 100% !important;
	}

	.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-update {
		margin-top: 15px !important;
	}
	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date {
		margin-right: 0;
	}
}

.tommy-zeb-wrapper.tommy-zeb-wrapper-mini.tommy-zeb-mini-collapse .tommy-zeb-left {
	justify-content: center !important;
}


.tommy-zeb-accommodation-types.ng-hide {
    display: block !important;  
} 

@media (max-width: 767px) {  
	.tommy-zeb-wrapper.tommy-zeb-wrapper-mini.tommy-zeb-mini-collapse .tommy-zeb-left {
		justify-content: left !important;
	} 

	.tommy-zeb-person-categories {
		margin-right: 15px !important;
	} 

	.tommy-zeb-accommodation-types h2.ng-binding {
		text-align: left;
	}   
} 