&.default {
	padding-top: 85px;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
// .default-card {}

.slidertext-container {
	margin-bottom: 75px;

	.container-holder {
		background: $yellow;
		border-radius: 8px;
		overflow: hidden;
		min-height: 488px;
		align-items: stretch !important;
		@include media-breakpoint-down(md) {
			margin-left: 0;
			margin-right: 0;
		}

		.column {
			margin-bottom: 0;
			.resource-image {
				height: 100%;
				width: 101%;
				img {
					height: 100%;
					object-fit: cover;
				}
			}

			&.one {
				padding-right: 0 !important;
			}

			&.two {
				padding-left: 15px !important;
			}

			&.col-4 {
				display: flex;
				flex-flow: row wrap;
				align-items: center;

				.wysiwyg {
					max-width: 80%;
					margin-left: auto;
					margin-right: auto;
					h2,
					h1 {
						font-size: 150%;
					}
				}
				@include media-breakpoint-down(md) {
					flex: 0 0 100%;
					max-width: 100%;
					padding-top: 15px;
					padding-left: 15px !important;
					padding-right: 15px !important;
				}

				h2, h1 {
					color: $secondary;
				}
			}

			&.col-8 {
				@include media-breakpoint-down(md) {
					order: -1;
					flex: 0 0 100%;
					max-width: 100%;
					padding-left: 0 !important;
					padding-right: 0 !important;
				}

				&:first-of-type {
					padding-left: 0;
				}

				&:last-of-type {
					padding-right: 0;
				}
			}
		}
	}

	.owl-dots {
		display: none;
	}
}

&.home .container-two-columns {
	.container-holder {
		align-items: center;
	}
}

@media (max-width: 991px) {
	h1 {
		font-size: 2.25rem;
	}
	// h2 {}
	// h3 {}
	// h4 {}
}

.modal .modal-dialog {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%) !important;
}

@media (max-width: 767px) {
	.modal-content {
		width: 100%;
		max-width: 80vw;
	}
}

&.accommodation-detail {
	.lead-section {
		.info-nav {
			display: none !important;
		}
	}
}
