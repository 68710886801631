// header
.header {
	z-index: 10000;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: $yellow;
	transition: 0.5s;

	> [class*='container-'] {
		.container-holder {
			height: 85px;

			.column {
				padding-right: 0;
				height: 85px;
				justify-content: space-between;
			}
		}
	}

	&.sticky {
		box-shadow: $shadow;
	}

	> [class*="container-"] {
		.container-holder {
			> .column {
				@extend .align-items-center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			color: $secondary;
		}
	}

	// logo
	.logo {
		margin: 10px 10px 10px 0;
		@include media-breakpoint-down(md) {
			max-width: 140px;
			margin: 0;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		padding-left: 135px;

		a {
			color: #000;
			font-family: $headings-font-family;
		}

		.nav-item {
			position: relative;
			@media(min-width: 992px) {
				&:hover {
					&:after {
						content: '';
						width: 100%;
						height: 2px;
						position: absolute;
						background: $secondary;
						left: 0;
						bottom: 0;
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.dropdown-menu {
				background-color: $yellow;
				border-color: $white;

				&::before {
					border-bottom-color: $white !important;
				}
				&::after {
					border-bottom-color: $yellow !important;
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		@media(max-width: 575px) {
			top: 75px;
			position: absolute;
			right: 120px;
		}
	}

	.header_button {
		height: 100%;

		a {
			background: #1E5D5C;
			color: #fff;
			text-decoration: none;
			width: 184px;
			height: 167px;
			display: block;
			clip-path: circle(92.5% at 93% 0);
			@include media-breakpoint-down(md) {
				width: 133px;
				height: 127px;
			}

			.list-item-title {
				display: inline-block;
				text-align: center;
				text-transform: uppercase;
				font-family: $font-family-secondary;
				font-size: $h4-font-size;
				font-weight: 600;
				position: relative;
				left: 64px;
				top: 30px;
				background: $secondary;
				z-index: +1;
				border-radius: 6px;
				padding-bottom: 5px;
				@include media-breakpoint-down(md) {
					font-size: 1.25rem;
					left: 40px;
					top: 20px;
				}
			}

			.list-item-suffix {
				display: block;
				margin-left: auto;
				margin-right: auto;
				text-align: center;
				font-size: $font-size-small;
				max-width: 92px;
				position: relative;
				left: 21px;
				top: 30px;
				line-height: 14px;
				background: $secondary;
				@include media-breakpoint-down(md) {
					font-size: 0.625rem;
					left: 13px;
					top: 17px;
				}

				&:after {
					background: url('/images/zon.svg');
					position: absolute;
					right: -32px;
					width: 67px;
					height: 67px;
					top: -70px;
					content: '';
					background-repeat: no-repeat;
					background-size: contain;
					background-position: 50%;
					@include media-breakpoint-down(md) {
						right: -29px;
						top: -65px;
					}
				}
			}

			&:hover {
				.list-item-suffix {
					&:after {
						animation: rotate 8s linear infinite both;
					}
				}
			}
		}
	}
}

// home
&.home {
	.header {
		@include media-breakpoint-up(xl) {
			position: fixed;
		}
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

@media(max-width: 1199px) {
	#default_menu {
		position: fixed;
		top: 80px;
		background: $yellow;
		left: 0;
		width: 100%;
		padding-left: 15px;
	}
}
