// bg-*
// =========================================================================
section {
	+ .eyecatcher {
		+ section {
			padding: 12vh 0;
			margin: 0;
			@include media-breakpoint-down(md) {
				padding: 6vh 0;
			}

			&:before {
				top: -25px;
				content: '';
				background: url('/images/golfjes_wit.svg');
				width: 100%;
				height: 47px;
				position: absolute;
				left: 0;
				background-size: 100% 100%;
				z-index: 1;
				@include media-breakpoint-down(md) {
					background-size: cover;
				}
			}
		}

		&:last-of-type {
			margin-bottom: 35px;

			&:after {
				bottom: -25px;
				content: '';
				background: url('/images/golfjes_wit.svg');
				width: 100%;
				height: 47px;
				position: absolute;
				left: 0;
				background-size: 100% 100%;
				z-index: 1;
				@include media-breakpoint-down(md) {
					background-size: cover;
				}
			}
		}
	}
}

section {
	position: relative;

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;

		* {
			color: #000;
		}
	}

	&.bg-light {
		&:before,
		&:after {
			content: '';
			background: url('/images/intro_golfjes.svg');
			width: 100%;
			height: 47px;
			position: absolute;
			left: 0;
			z-index: 1;
			background-size: cover;
		}

		&:before {
			top: -25px;
		}

		&:after {
			bottom: -25px;
			// transform: rotate(180deg);
		}

		+ .content-section {
			padding: 12vh 0;
			@include media-breakpoint-down(md) {
				padding: 6vh 0;
			}
		}

		h2 {
			color: $secondary;
		}
	}

	.container:not(:first-of-type) {
		margin-top: 55px;
	}

	&:not(.bg-light,.bg-dark) {
		* {
			color: $secondary;
		}

		h3 {
			font-weight: 400;
			font-family: $font-family-primary;
		}

		.wysiwyg {
			margin-bottom: 35px;
			@include media-breakpoint-down(md) {
				margin-bottom: 15px;
			}

			p,
			ul {
				max-width: 720px;
				margin: 0 auto 15px;
			}
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	padding: 6vh 0;
	background: $primary;
	position: relative;
	@extend .bg-light;

	.container-one-column:not(.container-wide) {
		.container-holder {
			max-width: 850px;
			margin: 0 auto 15px;
			text-align: center;

			.title,
			.page-title {
				h1 {
					color: $green-dark;
					@include media-breakpoint-down(md) {
						font-size: 23px;
					}
				}

				h3 {
					font-family: $font-family-primary;
					font-weight: 400;
					@include media-breakpoint-down(md) {
						max-width: 315px;
						margin-left: auto;
						margin-right: auto;
					}
				}
			}

			.subtitle {
				h5 {
					font-family: $font-family-primary;
					font-weight: 400;
				}
			}

			.wysiwyg {
				max-width: 700px;
				margin: 0 auto;
			}
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// lead section other pages not home
&:not(:has(.eyecatcher)) {
	&:not(:is(.home, .service-overview)){
		.lead-section {
			&::before {
				background: url('/images/banner_two-golfjes.svg');
				top: unset;
				z-index: 2;

				height: 82px;
				left: 0;
				bottom: -30px;
				background-size: cover;
				background-position-x: 20px;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;

	// intro/outro
	.intro,
	.outro {
		max-width: 750px;

		.container-holder {
			text-align: center;
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

// banner-section
// =========================================================================
// .banner-section {}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 6vh 0;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;
	margin-bottom: 12vh !important;
	@extend .bg-light;
	@include media-breakpoint-down(md) {
		margin-bottom: 6vh !important;
	}

	h2 {
		margin-bottom: 30px;
	}
}


.impression-section {
	.container-two-columns {
		&:nth-of-type(2n + 2) {
			.container-holder {
				.column.two {
					@include media-breakpoint-down(md) {
						order: -1;
					}
				}
			}
		}
	}
}
